import React from 'react';

const {
    Provider: HammerServiceProvider,
    Consumer: HammerServiceConsumer
} = React.createContext();

export {
    HammerServiceConsumer,
    HammerServiceProvider
};
