export default class HammerService {
    apiPrefix = '/api/v1'
    data = [
        {
            'guid': '78a0feb6-12c0-11de-817f-001fc670a6dd',
            'name': 'Артюхова М.С.'
        },
        {
            'guid': '22cbb588-11bc-11e1-82f7-001fc670a6dd',
            'name': 'Авраменко И. Б.'
        },
        {
            'guid': 'ac9264bc-cb1b-11e1-87e7-001fc670a6dd',
            'name': 'Давиденко Е. А.'
        }
    ]

    status = {
        'status': 'success',
        'message': 'success',
        'data': {
            'username': 'Вознесенский Ю. В.',
            'guid': '660c4340-b6d1-11e2-8932-d43d7e2bc9a4'
        }
    }
    //vozuv

    getLocalTokenHeader = () => {
        if (window.localStorage.getItem('authToken')) {
            return `Bearer ${window.localStorage.authToken}`;
        }

        throw new Error('Token is missing');
    };

    suggestClient(word = 'ddd') {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (Math.random() > 0.75) {
                    reject(new Error('Something bad happened!!!'))
                } else {
                    resolve(this.data);
                }
            }, 700);
        });
    }

    async login(data) {
        const res = await fetch(
            `${this.apiPrefix}/login`,
            {
                method: 'post',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
                credentials: 'include'
            });
        if (!res.ok) {
            throw new Error('Could not fetch login' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async getStatus() {
        const token = this.getLocalTokenHeader()
        const res = await fetch(
            `${this.apiPrefix}/status`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async getItem(itemGuid) {
        const token = this.getLocalTokenHeader()
        const res = await fetch(
            `${this.apiPrefix}/goods/`,
            {
                body: JSON.stringify({guid: itemGuid}),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async search(query) {
        const token = this.getLocalTokenHeader()
        const res = await fetch(
            `${this.apiPrefix}/contragents/search/${query}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async productSearch(query) {
        const token = this.getLocalTokenHeader()
        const res = await fetch(
            `${this.apiPrefix}/goods/search/${query}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }


    async getPeriod(period, clientId) {
        const token = this.getLocalTokenHeader()
        const res = await fetch(
            `${this.apiPrefix}/orders/period/${clientId}`,
            {
                body: JSON.stringify(period),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async pushCache(opts) {
        const token = this.getLocalTokenHeader()
        //console.log(opts);
        const res = await fetch(
            `${this.apiPrefix}/cart/${opts.clientGuid}`,
            {
                body: JSON.stringify(opts.data),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }

    async pushOrder(opts) {
        const token = this.getLocalTokenHeader()
        console.log(opts);
        const res = await fetch(
            `${this.apiPrefix}/orders/push`,
            {
                body: JSON.stringify(opts),
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token
                },
                credentials: 'include'
            });

        if (!res.ok) {
            throw new Error('Could not fetch status' +
                `, received ${res.status}`)
        }
        return await res.json();
    }
}