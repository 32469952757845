import React from "react";
import { HammerServiceConsumer } from '../hammer-service-context';

const withHammerService = () => (Wrapped) => {
    return (props) => {
        return (
            <HammerServiceConsumer>
                {
                    (hammerService) => {
                        return (
                            <Wrapped
                            {...props}
                            hammerService={hammerService}/>
                        );
                    }
                }
            </HammerServiceConsumer>
        );
    }
};

export default withHammerService;
