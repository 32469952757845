// Status
const statusRequested = () => {
    return { type: 'FETCH_STATUS_REQUEST' }
};

const statusLoaded = (status) => {
    return {
        type: 'FETCH_STATUS_SUCCESS',
        payload: status
    }
};

const statusError = (error) => {
    return {
        type: 'FETCH_STATUS_FAILURE',
        payload: error
    }
};

// Login

const changedLoginValues = (data) => {
    return {
        type: 'CHANGE_LOGIN_FORM',
        payload: data
    }
}

const loginRequested = () => {
    return { type: 'FETCH_LOGIN_REQUEST' }
};

const loginSuccess = (data) => {
    return {
        type: 'FETCH_LOGIN_SUCCESS',
        payload: data
    }
};

const loginError = (error) => {
    return {
        type: 'FETCH_LOGIN_FAILURE',
        payload: error
    }
};

const clearToken = () => {
    return {
        type: 'CLEAR_TOKEN'
    }
}

// Search

const searchRequested = () => {
    return { type: 'FETCH_SEARCH_REQUEST' }
};

const searchSuccess = (data) => {
    return {
        type: 'FETCH_SEARCH_SUCCESS',
        payload: data
    }
}

const searchFail = (err) => {
    return {
        type: 'FETCH_SEARCH_FAILURE',
        payload: err
    }
}

const selectedClient = (id) => {
    return {
        type: 'SELECT_CLIENT_REQUEST',
        payload: id
    }
}

// Period data
const periodRequested = () => {
    return { type: 'FETCH_PERIOD_REQUEST' }
};

const periodSuccess = (data) => {
    return {
        type: 'FETCH_PERIOD_SUCCESS',
        payload: data
    }
}

const periodFail = (err) => {
    return {
        type: 'FETCH_PERIOD_FAILURE',
        payload: err
    }
}

const periodAliasChanged = (alias) => {
    return {
        type: 'PERIOD_ALIAS_CHANGED',
        payload: alias
    }
};

const clearClient = () => {
    return {
        type: 'CLEAR_CLIENT'
    }
}

// ITEM
const itemRequested = () => {
    return { type: 'FETCH_ITEM_REQUEST' }
};

const itemLoaded = (data) => {
    return {
        type: 'FETCH_ITEM_SUCCESS',
        payload: data
    }
};

const itemFail = (error) => {
    return {
        type: 'FETCH_ITEM_FAILURE',
        payload: error
    }
};

const selectedItem = (id) => {
    return {
        type: 'ITEM_SELECTED',
        payload: id
    }
}

const sortingSelected = (sortBy) => {
    return {
        type: 'SORTING_SELECTED',
        payload: sortBy
    }
}

const brandSelected = (brand) => {
    return {
        type: 'BRAND_SELECTED',
        payload: brand
    }
}

const cartDataChange = (itemId, count) => {
    return {
        type: 'CART_CHANGED',
        payload: {itemId, count}
    }
}

// Order
const orderRequested = () => {
    return { type: 'FETCH_ORDER_REQUEST' }
};

const orderLoaded = (data) => {
    return {
        type: 'FETCH_ORDER_SUCCESS',
        payload: data
    }
};

const orderFail = (error) => {
    return {
        type: 'FETCH_ORDER_FAILURE',
        payload: error
    }
};

const clearResult = () => {
    return {
        type: 'CLEAR_RESULT'
    }
}

const commentChange = (text) => {
    return {
        type: 'COMMENT_CHANGED',
        payload: text
    }
}

const pageChange = (pageNum) => {
    return {
        type: 'PAGE_CHANGED',
        payload: pageNum
    }
}

const productPageChange = (pageNum) => {
    return {
        type: 'PRODUCT_PAGE_CHANGED',
        payload: pageNum
    }
}

// Product search

const searchProductRequested = (data) => {
    return {
        type: 'FETCH_PRODUCT_SEARCH_REQUEST',
        payload: data
    }
};

const searchProductSuccess = (data) => {
    return {
        type: 'FETCH_PRODUCT_SEARCH_SUCCESS',
        payload: data
    }
}

const searchProductFail = (err) => {
    return {
        type: 'FETCH_PRODUCT_SEARCH_FAILURE',
        payload: err
    }
}

const productsSortingSelected = (sortBy) => {
    return {
        type: 'PRODUCTS_SORTING_SELECTED',
        payload: sortBy
    }
}

const productsBrandSelected = (brand) => {
    return {
        type: 'PRODUCTS_BRAND_SELECTED',
        payload: brand
    }
}


// PRODUCT ITEM
const productItemRequested = () => {
    return { type: 'FETCH_PRODUCT_ITEM_REQUEST' }
};

const productItemLoaded = (data) => {
    return {
        type: 'FETCH_PRODUCT_ITEM_SUCCESS',
        payload: data
    }
};

const productItemFail = (error) => {
    return {
        type: 'FETCH_PRODUCT_ITEM_FAILURE',
        payload: error
    }
};

const selectedProductItem = (id) => {
    return {
        type: 'PRODUCT_ITEM_SELECTED',
        payload: id
    }
}

// PRODUCT CART
const addProductToCart = (product) => {
    return {
        type: 'PUSH_PRODUCT_TO_CART',
        payload: product
    }
}

const removeProductFromCart = (product) => {
    return {
        type: 'REMOVE_PRODUCT_FROM_CART',
        payload: product
    }
}

const changeProductCount = (product) => {
    return {
        type: 'CHANGE_PRODUCT_COUNT_AT_CART',
        payload: product
    }
}

const fetchStatus = (hammerService, dispatch) =>  () => {
    console.log('fetchStatus')
    dispatch(statusRequested());
    hammerService.getStatus()
        .then((data) => dispatch(statusLoaded(data)))
        .catch((err) => dispatch(statusError(err)));
}

// Cache
const cacheRequested = () => {
    return { type: 'FETCH_CACHE_REQUEST' }
}

const cacheLoaded = (data) => {
    return {
        type: 'CACHE_SUCCESS',
        payload: data
    }
}

const cacheFail = (data) => {
    return {
        type: 'CACHE_FAIL',
        payload: data
    }
}


export {
    fetchStatus,
    changedLoginValues,
    loginRequested,
    loginSuccess,
    loginError,
    clearToken,
    searchSuccess,
    searchFail,
    searchRequested,
    selectedClient,
    periodRequested,
    periodSuccess,
    periodFail,
    periodAliasChanged,
    clearClient,
    itemRequested,
    itemLoaded,
    itemFail,
    selectedItem,
    sortingSelected,
    brandSelected,
    cartDataChange,
    orderRequested,
    orderLoaded,
    orderFail,
    clearResult,
    commentChange,
    pageChange,
    searchProductRequested,
    searchProductSuccess,
    searchProductFail,
    productsSortingSelected,
    productsBrandSelected,
    productPageChange,
    selectedProductItem,
    productItemRequested,
    productItemLoaded,
    productItemFail,
    addProductToCart,
    removeProductFromCart,
    changeProductCount,
    cacheRequested,
    cacheLoaded,
    cacheFail
};
