import React from 'react';
import Cart from '../cart';

const CartPage = () => {
    return(
        <Cart />
    );
}

export default CartPage;
