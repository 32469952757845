//import createHistory from "history/createBrowserHistory";
//import history from
// export default createHistory();
//import {createBrowserHistory as createHistory} from 'history';
//export default createHistory();

//import { createBrowserHistory as createHistory } from 'history'
//const history = createHistory()
//import { createBrowserHistory } from "history";
//export default createBrowserHistory();


import { createBrowserHistory } from 'history';
export default createBrowserHistory();