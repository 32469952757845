import React, {Component} from 'react';
import {Button, Col, Input, InputNumber, Modal, notification, Row, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import {ArrowLeftOutlined, ExclamationCircleOutlined, ShoppingCartOutlined, CopyOutlined} from "@ant-design/icons";
import {compose, history} from "../../utils";
import withHammerService from "../hoc/with-hammer-service";
import {connect} from "react-redux";
import {
    commentChange, orderFail, orderLoaded, orderRequested,
    clearResult, clearClient
} from "../../actions";
import EmptyLocale from "../empty-locale";

const {TextArea} = Input;

const { Title, Text } = Typography;

class Cart extends Component{
    render(){
        const {
            commentChanged, comment,
            pushOrder, clientId, blockSubmit,
            clearResult, cartItems, orderResult, periodData
        } = this.props;

        //console.log("comment", comment);
        const ordersData = (periodData) ? periodData.data : [];

        const visibleModal = (orderResult) ? true : false;

        const result = (orderResult && orderResult.status === 'success') ? orderResult.result.map((i, k) => {
            return (
                <p key={k}>
                    <Text type="secondary">{i.name}</Text>
                </p>
            );
        }) : 'ОШИБКА!!!';


        const columns = [
            {
                title: 'Наименование',
                dataIndex: 'name',
                width: '75%',
                className: 'orders-table-column'
            },
            {
                title: 'Заказ',
                dataIndex: 'to_order',
                width: '25%',
                align: 'center',
                className: 'orders-table-column'
            }
        ];

        //console.log("cartItems", cartItems);
        const full = cartItems.concat(ordersData.filter((item) => {return item.to_order > 0}));

        //console.log("full", full);

        const newDs = full.map((item, key) => {
            //console.log("itemsssss", item);
            return {
                id: item.guid,
                name: (
                    <>
                        <Text type="secondary">Бренд: {item.brand}</Text>
                        <br/>
                        <Text
                            //onClick={() => selectedItem(item.guid)}
                            strong>{item.name}</Text>
                        <br/>
                        <Text type="secondary">Артикул: {item.sku}</Text>
                    </>
                ),
                to_order: (
                    <Text type="secondary">{item.to_order}</Text>
                )
            }
        });

        const orderDs = full;

        return (
            <div style={{paddingTop: '10px'}}>
                <Row style={{marginBottom: '10px'}}>
                    <Col span={12} style={{textAlign: 'left', paddingLeft: '15px'}}>
                        <Link to="/client">
                            <Button type="primary" shape="round" icon={<ArrowLeftOutlined/>} size={'small'}>Назад</Button>
                        </Link>
                    </Col>
                    <Col span={12} style={{textAlign: 'right', paddingRight: '15px'}}>
                        <Link to="/products">
                            <Button type="primary" shape="round" size={'small'}>Поиск товаров</Button>
                        </Link>
                    </Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto'}}>
                    <Title level={3}>Корзина</Title>
                    <Table
                        className={'orders-table'}
                        size={'small'}
                        bordered
                        columns={columns}
                        dataSource={newDs}
                        //locale={{ emptyText: <EmptyLocale/> }}
                        // pagination={{pageSize: 30, current: currentPage, onChange: (page) => {
                        //     pageChange(page);
                        //     //console.log(newDs)
                        // } }}
                        scroll={{y: 490}}/>
                </Row>

                <Row style={{width: "90%", margin: 'auto', display: 'block'}}>
                    <Col span={32}>
                        <TextArea
                            placeholder="Примечание к заказу"
                            onChange={(event) => commentChanged(event.target.value)}
                            //autoSize
                            style={{width: '100%', marginBottom: '10px'}}
                            //autoSize={true}
                            value={comment}
                            rows={2}/>
                    </Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto', marginBottom: '20px'}}>
                    <Button block
                            disabled={blockSubmit}
                            style={{width: "100%", margin: 'auto'}}
                            type="primary"
                            htmlType="submit"
                            onClick={() => pushOrder(orderDs, comment, clientId)}>Заказать</Button>
                </Row>

                <Modal
                    visible={visibleModal}
                    title="Заказ"
                    onOk={clearResult}
                    onCancel={clearResult}
                    afterClose={clearResult}
                    footer={[
                        <Button
                            type="primary"
                            onClick={clearResult}
                        >
                            ОК
                        </Button>,
                    ]}
                >
                    {result}
                </Modal>
            </div>
        );
    }
}

const openNotification = () => {
    notification.open({
        message: 'Внимание!!!',
        description:
            'Заказ пуст! Выберите позиции.',
        onClick: () => {
            console.log('Notification Clicked!');
        },
        icon: <ExclamationCircleOutlined style={{color: '#108ee9'}}/>,
    });
};

const mapStateToProps = (
    {products: {productsSearchValue, cartItems, foundBrands, foundProducts, sortBy, brand, currentPage},
    periodData, clientId, comment, blockSubmit, orderResult
    },
    ) => {
    return {productsSearchValue, foundBrands, foundProducts, clientId, comment,
        cartItems, brand, currentPage, blockSubmit, periodData, orderResult};
};

const mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        clearResult: () => {
            history.push("/");
            dispatch(clearResult())
            dispatch(clearClient())
        },
        pushOrder: (data, comment, clientId) => {
            const items = data.filter((i, k) => {
                return i.to_order > 0
            });
            //console.log(comment, items, clientId);
            if (items.length) {
                const req = items.map((i, k) => {
                    return {id: i.guid, amount: i.to_order}
                });

                const requestData = {
                    id: clientId,
                    data: req,
                    comment: comment
                };

                dispatch(orderRequested())
                hammerService.pushOrder(requestData)
                    .then((data) => {
                        dispatch(orderLoaded(data))
                    })
                    .catch((err) => {
                        dispatch(orderFail(err))
                    });
            } else {
                openNotification();
            }
        },
        commentChanged: (text) => dispatch(commentChange(text))
    }
}

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Cart);