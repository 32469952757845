import React from "react";
import { Spin, Row, Col } from 'antd';
import './spinner.css';

const Spinner = () => {
    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
            <Col span={1} style={{textAlign: "center"}} >
                 <Spin
                     size="large" >
                 </Spin>
            </Col>
        </Row>
    );
}

export default Spinner;
