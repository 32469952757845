import React, {Component} from 'react';
import {
    itemRequested,
    itemLoaded,
    cartDataChange,
    itemFail
} from '../../actions';
import Spinner from '../spinner';
import {connect} from "react-redux";
import {compose} from "../../utils";
import {Link, Redirect} from 'react-router-dom';
import withHammerService from "../hoc/with-hammer-service";

import './item-card.css';
import {Button, Row, Col, Card, Form, InputNumber} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

class ItemCard extends Component {

    componentDidMount() {
        const {itemGuid, getItem} = this.props;
        if (itemGuid) {
            getItem(itemGuid);
        }
    }

    render() {
        const {loading, itemData, itemGuid, periodData, cartChanged} = this.props;

        if (loading) {
            return <Spinner/>;
        }

        if (!itemGuid) {
            return <Redirect to='/'/>;
        }

        if (!itemData) {
            return '';
        }

        const ordersData = (periodData) ? periodData.data : [];
        const items = ordersData.filter((e) => {return itemData.guid === e.guid});

        let inCart = 0;
        let itemIndex;
        if (items.length) {
            inCart = items[0].to_order;
            itemIndex = items[0].list_index;
        }

        return (
            <React.Fragment>
            <Row type="flex" justify="center" align="middle">
                <Col span={22} style={{paddingTop: '10px'}}>
                    <Link to="/client">
                        <Button type="primary" shape="round" icon={<ArrowLeftOutlined/>} size={'small'}>Назад</Button>
                    </Link>
                    <br/>
                    <br/>
                    <Card
                        hoverable
                        style={{width: '90%', margin: 'auto'}}
                        cover={<img alt="" src={itemData.photo}/>}
                    >


                        {itemData.name}
                        <br/>
                        {itemData.sku}
                        <br/>
                        {itemData.brand}
                        <br/>
                        {itemData.description}
                        <br/>
                        <b>Остаток:</b> {itemData.remain}
                        <br/>
                        <b>Цена:</b> {itemData.price}
                    </Card>
                </Col>
            </Row>

                <Row type="flex" justify="center" align="middle">
                    <Col span={22} style={{paddingTop: '10px'}}>
                        <Form
                            style={{width: '90%', margin: 'auto'}}
                            layout="vertical">
                            <Form.Item label="В корзине">
                                <InputNumber
                                    size={'large'}
                                    min={0} max={1000}
                                    defaultValue={inCart}
                                    style={{width: '50%'}}
                                    onChange={(data) => cartChanged(itemIndex, data)}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({itemData, itemGuid, loading, periodData}) => {
    return {itemData, itemGuid, loading, periodData};
};

const mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        getItem: (itemGuid) => {
            dispatch(itemRequested())
            hammerService.getItem(itemGuid)
                .then((data) => dispatch(itemLoaded(data)))
                .catch((err) => dispatch(itemFail(err)))
        },
        cartChanged: (itemId, quant) => dispatch(cartDataChange(itemId, quant)),
    }
};


export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ItemCard);
