import * as moment from 'moment';
//import fakeData from './fakeTable';

const debug = true;
const dateFrom = moment().subtract(1, 'years').format("YYYY-MM-DD");
const dateTo = moment().format("YYYY-MM-DD");

const productsInit = {
    foundProducts: [],
    foundBrands: [],
    cartedProducts: [],
    productsPage: 1,
    productsSearchValue: null,
    sortBy: 'name',
    itemData: null,
    itemGuid: null,
    loading: true,
    cartItems: []
};

const initialState = {
    logined: false,
    userData: {},
    loading: true,
    error: null,
    loginFormData: {},
    searchData: [],
    clientId: null,
    clientData: null,
    periodData: null,
    clientPeriod: [dateFrom, dateTo],
    clientPeriodAlias: 'year',
    shouldUpdate: false,
    loadedPeriodAlias: 'year',
    itemGuid: null,
    itemData: null,
    sortBy: 'name',
    brand: null,
    orderResult: null,
    blockSubmit: false,
    comment: '',
    currentPage: 1,
    products: productsInit
};

/*
const initialState2 = {
    logined: false,
    userData: {},
    loading: true,
    error: null,
    loginFormData: {},
    searchData: [],
    clientId: 67,
    //clientData: fakeData.client_data,
    //periodData: fakeData.data,
    //clientPeriod: [dateFrom, dateTo],
    clientPeriodAlias: 'year',
    shouldUpdate: false,
    loadedPeriodAlias: 'year',

    itemGuid: null,
    itemData: null,
    sortBy: 'brand',
    brand: null,
    orderResult: null,
    blockSubmit: false,
    comment: ''
};
*/

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_STATUS_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                loading: true,
                error: null
            }
        case 'FETCH_STATUS_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                userData: action.payload.data,
                logined: true,
                loading: false,
                error: null
            };
        case 'FETCH_STATUS_FAILURE':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                userData: {},
                logined: false,
                loading: false,
                error: null
            };
        case 'CHANGE_LOGIN_FORM':
            if (debug){
                console.log(action.type);
            }
            const loginFormData = state.loginFormData
            const newLoginFormData = {
                ...loginFormData,
                ...action.payload
            }
            return {
                ...state,
                loginFormData: newLoginFormData
            };

        case 'FETCH_LOGIN_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                logined: false,
                loading: true,
                error: null,
                userData: {}
            };
        case 'FETCH_LOGIN_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            const token = action.payload.auth_token;
            window.localStorage.setItem('authToken', token);
            return {
                ...state,
                logined: true,
                loading: false,
                error: null,
                userData: action.payload.user_data
            };

        case 'FETCH_LOGIN_FAILURE':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                logined: false,
                loading: false,
                error: null
            }

        case 'CLEAR_TOKEN':
            if (debug){
                console.log(action.type);
            }
            window.localStorage.clear();
            //console.log('page num changed');
            return {
                ...initialState
                //...state,
                //logined: false,
                //loading: false,
                //error: null,
                //userData: {},
                //currentPage: 1
            }
        case 'FETCH_SEARCH_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                searchData: action.payload.data
            }
        case 'FETCH_SEARCH_FAILURE':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state
            }
        case 'SELECT_CLIENT_REQUEST':
            if (debug){
                console.log(action.type);
            }

            return {
                ...state,
                clientId: action.payload
            }
        case 'FETCH_PERIOD_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                loading: true,
                periodData: null,
                shouldUpdate: false,
                loadedPeriodAlias: state.clientPeriodAlias
            }
        case 'FETCH_PERIOD_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            //console.log(action.payload);
            const cart_cache = action.payload.cart_cache;
            //console.log("cart_cache 2222", cart_cache);
            const newProducts = state.products;
            newProducts.cartItems = cart_cache;

            return {
                ...state,
                products: newProducts,
                periodData: action.payload.data,
                clientData: action.payload.client_data,
                loading: false
            }
        case 'FETCH_PERIOD_FAILURE':
            if (debug){
                console.log(action.type);
            }
            //console.log('page num changed');
            return {
                ...state,
                loading: false,
                currentPage: 1
            }
        case 'PERIOD_ALIAS_CHANGED':
            if (debug){
                console.log(action.type);
            }
            if (action.payload !== state.clientPeriodAlias){
                let period;
                switch (action.payload) {
                    case 'week':
                        period = [
                            moment().subtract(1, 'weeks').format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD")
                        ];
                        break;
                    case 'year':
                        period = [
                            moment().subtract(1, 'years').format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD")
                        ];
                        break;
                    case 'month':
                        period = [
                            moment().subtract(1, 'months').format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD")
                        ];
                        break;
                    default:
                        period = [
                            moment().subtract(1, 'weeks').format("YYYY-MM-DD"),
                            moment().format("YYYY-MM-DD")
                        ];
                }

                let shouldUpdate;
                if (state.loadedPeriodAlias === action.payload){
                    shouldUpdate = false;
                } else {
                    shouldUpdate = true;
                }

                //console.log('page num changed');

                return {
                    ...state,
                    clientPeriodAlias: action.payload,
                    clientPeriod: period,
                    shouldUpdate: shouldUpdate,
                    currentPage: 1
                }
            } else {
                return state;
            }
        case 'CLEAR_CLIENT':
            if (debug){
                console.log(action.type);
            }
            //console.log('page num changed');
            return {
                ...initialState
            }
            /*
            return {
                ...state,
                clientPeriod: [dateFrom, dateTo],
                clientId: null,
                clientData: null,
                periodData: null,
                clientPeriodAlias: 'year',
                shouldUpdate: false,
                loadedPeriodAlias: 'year',
                currentPage: 1,
                products: productsInit,
                sortBy: 'name',
            }
            */

        case 'FETCH_ITEM_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                loading: true,
                itemData: null
            }

        case 'FETCH_ITEM_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                loading: false,
                itemData: action.payload
            }
        case 'FETCH_ITEM_FAILURE':
            console.log(action.type);
            return {
                ...state,
                loading: false,
                itemData: action.payload
            }

        case 'ITEM_SELECTED':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                itemGuid: action.payload
            }
        case 'SORTING_SELECTED':
            if (debug){
                console.log(action.type);
                console.log(action.payload);
            }

            return {
                ...state,
                sortBy: action.payload
            }
        case 'BRAND_SELECTED':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                brand: action.payload
            }
        case 'CART_CHANGED':
            if (debug){
                console.log(action.type);
            }
            //console.log(action.type);
            //const {itemId, count} = action.payload
            //console.log("cart_changed", action.payload);
            const periodData = state.periodData;
            const items = periodData.data;
            //console.log(items.slice(0, 10));
            items[action.payload.itemId]['to_order'] = action.payload.count;
            periodData.data = items;
            return {
                ...state,
                periodData
            }
        case 'FETCH_ORDER_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                blockSubmit: true
            }
        case 'FETCH_ORDER_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                blockSubmit: false,
                orderResult: action.payload
            }
        case 'FETCH_ORDER_FAILURE':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                blockSubmit: true,
                orderResult: action.payload
            }
        case 'CLEAR_RESULT':
            if (debug){
                console.log(action.type);
            }
            //console.log('page num changed');
            return {
                ...state,
                orderResult: null,
                comment: '',
                currentPage: 1
            }

        case 'COMMENT_CHANGED':
            if (debug){
                console.log(action.type);
                console.log("action.payload", action.payload)
            }

            return {
                ...state,
                comment: action.payload
            }

        case 'PAGE_CHANGED':
            if (debug){
                console.log(action.type);
            }
            //console.log('page num changed');
            return {
                ...state,
                currentPage: action.payload
            }

        case 'PRODUCT_PAGE_CHANGED':
            if (debug){
                console.log(action.type);
            }
            const {products: products3} = state;
            products3.currentPage = action.payload

            return {
                ...state,
                ...products3
            }

        case 'FETCH_PRODUCT_SEARCH_REQUEST':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                products: {
                    foundBrands: [],
                    foundProducts: [],
                    cartedProducts: state.products.cartedProducts,
                    productsPage: 1,
                    productsSearchValue: action.payload,
                    sortBy: state.products.sortBy,
                    itemData: null,
                    itemGuid: null,
                    loading: true,
                    cartItems: state.products.cartItems
                }
            }
        case 'FETCH_PRODUCT_SEARCH_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            return{
                ...state,
                products: {
                    foundBrands: action.payload.brands,
                    foundProducts: action.payload.data,
                    cartedProducts: state.products.cartedProducts,
                    productsSearchValue: state.products.productsSearchValue,
                    productsPage: 1,
                    sortBy: 'name',
                    itemData: null,
                    itemGuid: null,
                    loading: false,
                    cartItems: state.products.cartItems
                }
            }
        case 'FETCH_PRODUCT_SEARCH_FAILURE':
            if (debug){
                console.log(action.type);
            }
            return {
                ...state,
                products: {
                    foundBrands: [],
                    foundProducts: [],
                    cartedProducts: state.products.cartedProducts,
                    productsPage: 1,
                    productsSearchValue: state.products.productsSearchValue,
                    sortBy: 'name',
                    itemData: null,
                    itemGuid: null,
                    loading: false,
                    cartItems: state.products.cartItems
                }
            }

        case 'PRODUCTS_SORTING_SELECTED':
            if (debug){
                console.log(action.type);
            }

            const {products} = state;
            products.sortBy = action.payload

            console.log(products.sortBy)

            return {
                ...state,
                ...products
            }

        case 'PRODUCTS_BRAND_SELECTED':
            if (debug){
                console.log(action.type);
            }
            const {products: products2} = state;
            products2.brand = action.payload
            return {
                ...state,
                ...products2
            }

        case 'PRODUCT_ITEM_SELECTED':
            if (debug){
                console.log(action.type);
            }
            const {products: products4} = state;
            products4.itemGuid = action.payload
            return {
                ...state,
                ...products4.itemGuid
            }

        case 'FETCH_PRODUCT_ITEM_REQUEST':
            if (debug){
                console.log(action.type);
            }
            const {products: products5} = state;
            products5.loading = true
            products5.itemData = null
            return {
                ...state,
                ...products5
            }

        case 'FETCH_PRODUCT_ITEM_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            const {products: products6} = state;
            products6.itemData = action.payload
            products6.loading = false
            return {
                ...state,
                ...products6
            }
        case 'FETCH_PRODUCT_ITEM_FAILURE':
            if (debug){
                console.log(action.type);
            }

            const {products: products7} = state;
            products7.itemData = action.payload
            products7.loading = false
            return {
                ...state,
                ...products7
            }

        case 'CHANGE_PRODUCT_COUNT_AT_CART':
            if (debug){
                console.log(action.type);
                console.log(action.payload);
            }
            const {products: products10} = state;
            const {data:product3, count} = action.payload;

            let newCartProducts3 = [];
            if (products10.cartItems.length){
                let wasThere = false;
                newCartProducts3 = products10.cartItems.map((item, index) => {
                    if (item.guid === product3.guid){
                        wasThere = true;
                        item.to_order = count
                    }
                    return item
                });
                if (! wasThere ){
                    product3.to_order = count;
                    newCartProducts3.push(product3);
                }
            } else {
                product3.to_order = count;
                newCartProducts3.push(product3);
            }

            products10.cartItems = newCartProducts3;


            return {
                ...state,
                ...products10
            }

        case 'PUSH_PRODUCT_TO_CART':
            if (debug){
                console.log(action.type);
            }
            const {products: products8} = state;
            const product = action.payload;
            console.log("product reducer", product);

            let newCartProducts = [];
            if (products8.cartItems.length){
                let wasThere = false;
                newCartProducts = products8.cartItems.map((item, index) => {
                    if (item.guid === product.guid){
                        console.log("item", item);
                        wasThere = true;
                        item.to_order = item.to_order + 1
                    }
                    return item
                });
                if (! wasThere ){
                    product.to_order = 1;
                    newCartProducts.push(product);
                }
            } else {
                product.to_order = 1;
                newCartProducts.push(product);
            }

            products8.cartItems = newCartProducts;

            //console.log("products8.cartItems", products8.cartItems);

            return {
                ...state,
                ...products8
            }

        case 'REMOVE_PRODUCT_FROM_CART':
            if (debug){
                console.log(action.type);
            }

            const {products: products9} = state;
            const product2 = action.payload;

            let newCartProducts2 = [];
            if (products9.cartItems.length){
                newCartProducts2 = products9.cartItems.map((item, index) => {
                    if (item.guid === product2.guid){
                        item.to_order = item.to_order - 1
                    }
                    if (item.to_order < 0) {
                        item.to_order = 0
                    }
                    return item
                });
            }

            products9.cartItems = newCartProducts2;

            //console.log("products9.cartItems", products9.cartItems);

            return {
                ...state,
                ...products9
            }

        case 'FETCH_CACHE_REQUEST':
            if (debug){
                console.log(action.type);
            }

            return {
                ...state
            }

        case 'CACHE_SUCCESS':
            if (debug){
                console.log(action.type);
            }
            const resultData = action.payload;

            return {
                ...state
            }

        case 'CACHE_FAIL':
            if (debug){
                console.log(action.type);
            }
            const failData = action.payload;

            return {
                ...state
            }


        default:
            return state;
    }
}

export default reducer;