import React, { Component }  from 'react';
import Spinner from '../spinner';
import { compose, history } from '../../utils';
import { connect } from 'react-redux';
import {
    fetchStatus,
    clearToken,
    searchSuccess,
    searchFail,
    searchRequested,
    selectedClient
} from '../../actions';
import withHammerService from "../hoc/with-hammer-service";
import { Redirect } from 'react-router-dom';
import { PageHeader, Button, Row, Col, AutoComplete, Card } from 'antd';
import 'antd/dist/antd.css';

//const { Option } = AutoComplete;

class SearchPage extends Component {
    componentDidMount() {
        if (!this.props.logined) {
            if (window.localStorage.getItem('authToken')) {
                this.props.fetchStatus();
            }
        }
    }

    render() {
        const {logined, loading, userData, clearToken, searchQuery, searchData,  selectedClient} = this.props;

        if (!logined){
            return <Redirect to='/login' />;
        }

        if (loading){
            return <Spinner/>;
        }

        return (
            <div>
                  <PageHeader
                      className="site-page-header"
                      subTitle={userData.username}
                      extra={[<Button key="1" onClick={clearToken}>Выход</Button>]}
                  />
                  <Row type="flex" justify="center" align="middle" style={{minHeight: '70vh'}}>
                      <Col span={22} >
                          <Card title="Поиск контрагента">
                            <AutoComplete
                                //onSearch={handleSearch}
                                onSelect={(value, option) =>  {
                                    selectedClient(option.id);

                                }}
                                placeholder="Введите название"
                                style={{ width: '100%' }}
                                onSearch={(data) => searchQuery(data)}
                                options={searchData}
                            >

                            </AutoComplete>
                          </Card>
                      </Col>
                  </Row>
            </div>
        );
    }
};

const mapStateToProps = ({logined, loading, error, userData, searchData}) => {
    return { logined, loading, error, userData, searchData };
};

const  mapDispatchToProps = (dispatch, { hammerService }) => {
    return {
        fetchStatus: fetchStatus(hammerService, dispatch),
        clearToken: () => dispatch(clearToken()),
        searchQuery: (query) => {
            const queryString = query.trim()
            dispatch(searchRequested())
            if (queryString.length >= 3) {
                hammerService.search(queryString)
                    .then((data) => dispatch(searchSuccess(data)))
                    .catch((err) => dispatch(searchFail(err)))
            }
        },
        selectedClient: (id) => {
            dispatch(selectedClient(id));
            history.push("/client");
        }
    };
}

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SearchPage);