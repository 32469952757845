import React, {Component} from "react";
import {Button} from 'antd';
//import {ArrowLeftOutlined, CheckOutlined} from '@ant-design/icons';
import {compose, history} from '../../utils';
import withHammerService from "../hoc/with-hammer-service";
import {connect} from "react-redux";
//import {Redirect} from 'react-router-dom';
import {sortBy as sorter} from "lodash";
import {Table, Row, Col, Select, InputNumber, notification, Modal, Input} from 'antd';
import Text from "antd/lib/typography/Text";
import {ExclamationCircleOutlined} from '@ant-design/icons';

import './orders-table.css';
import {
    selectedItem,
    sortingSelected,
    brandSelected,
    cartDataChange,
    orderRequested,
    orderLoaded,
    orderFail,
    clearResult,
    commentChange,
    pageChange,
    cacheRequested,
    cacheLoaded,
    cacheFail
} from "../../actions";

const {Option} = Select;
const {TextArea} = Input;

class OrdersTable extends Component {

    constructor() {
        super();
        this.onCartChanged = this.onCartChanged.bind(this);
    }

    onCartChanged(itemId, quant) {
        this.props.cartChanged(itemId, quant);
        setTimeout(() => {
            const ordersData = (this.props.periodData.data) ? this.props.periodData.data : [];
            const full = this.props.cartItems.concat(ordersData.filter((item) => {return item.to_order > 0}));
            const res = full.map((item, key) => {
                return {
                    id: item.guid,
                    amount: item.to_order
                };
            });
            this.props.pushCache(res, this.props.clientData.guid);
        }, 500);
    }

    render() {
        const {
            periodData, selectedItem, brand, sortBy, blockSubmit, clearResult, commentChanged, comment,
            selectBrand, selectSorting, cartChanged, pushOrder, clientId, orderResult, pageChange, currentPage
        } = this.props;

        //console.log("pageNum", currentPage);

        const visibleModal = (orderResult) ? true : false;

        //console.log("periodData", periodData);
        const ordersData = periodData.data;
        const brands = periodData.brands;

        //console.log(brands)

        const columns = [
            {
                title: 'Наименование',
                dataIndex: 'name',
                width: '75%',
                className: 'orders-table-column'
            },
            {
                title: 'Заказ',
                dataIndex: 'to_order',
                width: '25%',
                align: 'center',
                className: 'orders-table-column'
            }
        ];

        let newDs;
        if (sortBy === 'to_order') {
            newDs = sorter(ordersData, (o) => {
                return o.to_order
            });
        } else {
            newDs = sorter(ordersData, (o) => {
                return o[sortBy]
            });
        }

        //console.log("oooo", ordersData);

        const dataSource = newDs.map((item, index) => {
            //console.log("item", periodData.data[item.list_index].to_order);
            return {
                key: index,
                sortBrand: item.brand,
                brand: <Text>{item.brand}</Text>,
                name: (
                    <>
                        <Text type="secondary">Бренд: {item.brand}</Text>
                        <br/>
                        <Text
                            onClick={() => selectedItem(item.guid)}
                            strong>{item.name}</Text>
                        <br/>
                        <Text type="secondary">Артикул: {item.sku}</Text>
                        <br/>
                        <Text type="secondary">Последняя доставка: {item.delivery_date}</Text>
                        <br/>
                        <Text type="secondary">Количество: {item.amount}</Text>
                        <br/>
                        <Text>Остаток: </Text><Text type="secondary">{item.remain}</Text>
                        <br/>
                        <Text>Цена: </Text><Text type="secondary">{item.price}</Text>
                    </>
                ),
                to_order: (<InputNumber
                        size={'large'}
                        min={0} max={1000}
                        defaultValue={item.to_order}
                        //value={periodData.data[item.list_index].to_order}
                        style={{width: '90%'}}
                        onChange={(data) => this.onCartChanged(item.list_index, data)}
                    />)
            }
        });

        let ds;
        if (brand) {
            ds = dataSource.filter((item) => item.sortBrand === brand);
        } else {
            ds = dataSource;
        }

        const brandsOptions = brands.map((brand, index) => {
            return <Option key={index} value={brand}>{brand}</Option>
        });

        const result = (orderResult && orderResult.status === 'success') ? orderResult.result.map((i, k) => {
            return (
                <p key={k}>
                    <Text type="secondary">{i.name}</Text>
                </p>
            );
        }) : 'ОШИБКА!!!';

        return (
            <React.Fragment>
                <Row style={{width: "90%", margin: 'auto', marginTop: '-7px'}}>
                    <Col span={12}><Text type="secondary">Сортировка: </Text></Col>
                    <Col span={12}><Text type="secondary">Отбор: </Text></Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto', marginTop: '0px', marginBottom: '10px'}}>
                    <Col span={12}>
                        <Select
                            ref={(select) => this.brandSelect = select}
                            size={'default'}
                            defaultValue={null}
                            value={sortBy}
                            onChange={(value) => selectSorting(value)}
                            style={{width: '100%'}}
                        >
                            {[
                                {value: 'brand', alias: 'по бренду'},
                                {value: 'sku', alias: 'по артикулу'},
                                {value: 'name', alias: 'по наименованию'},
                                {value: 'delivery_date', alias: 'по дате посл. доставки'}
                            ].map((item, index) => {
                                return <Option key={index} value={item.value}>{item.alias}</Option>
                            })}
                         </Select>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary"></Text>
                        <Select
                            ref={(select) => this.brandSelect = select}
                            allowClear={true}
                            //mode="multiple"
                            placeholder="Бренд фильтр"
                            size={'default'}
                            defaultValue={null}
                            value={brand}
                            onChange={(value) => {
                                this.brandSelect.blur();
                                selectBrand(value);
                            }}
                            style={{width: '100%'}}>
                            {brandsOptions}
                        </Select>
                    </Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto'}}>
                    <Table
                        className={'orders-table'}
                        size={'small'}
                        bordered
                        columns={columns}
                        dataSource={ds}
                        pagination={{pageSize: 30, current: currentPage, onChange: (page) => {
                            pageChange(page);
                            //console.log(newDs)
                        } }}
                        scroll={{y: 420}}/>
                </Row>
                {/*<Row style={{width: "90%", margin: 'auto', display: 'block'}}>*/}
                {/*    <Col span={32}>*/}
                {/*        <TextArea*/}
                {/*            placeholder="Примечание к заказу"*/}
                {/*            onChange={(event) => commentChanged(event.target.value)}*/}
                {/*            //autoSize*/}
                {/*            style={{width: '100%', marginBottom: '10px'}}*/}
                {/*            //autoSize={true}*/}
                {/*            value={comment}*/}
                {/*            rows={2}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row style={{width: "90%", margin: 'auto', marginBottom: '20px'}}>*/}
                {/*    <Button block*/}
                {/*            disabled={blockSubmit}*/}
                {/*            style={{width: "100%", margin: 'auto'}}*/}
                {/*            type="primary"*/}
                {/*            htmlType="submit"*/}
                {/*            onClick={() => pushOrder(newDs, comment, clientId)}>Заказать</Button>*/}
                {/*</Row>*/}

                {/*<Modal*/}
                {/*    visible={visibleModal}*/}
                {/*    title="Заказ"*/}
                {/*    onOk={clearResult}*/}
                {/*    onCancel={clearResult}*/}
                {/*    footer={[*/}
                {/*        <Button*/}
                {/*            type="primary"*/}
                {/*            onClick={clearResult}*/}
                {/*        >*/}
                {/*            ОК*/}
                {/*        </Button>,*/}
                {/*    ]}*/}
                {/*>*/}
                {/*    {result}*/}
                {/*</Modal>*/}
            </React.Fragment>
        );
    }
};

const openNotification = () => {
    notification.open({
        message: 'Внимание!!!',
        description:
            'Заказ пуст! Выберите позиции.',
        onClick: () => {
            console.log('Notification Clicked!');
        },
        icon: <ExclamationCircleOutlined style={{color: '#108ee9'}}/>,
    });
};

const mapStateToProps = ({products: {cartItems}, periodData, brand, sortBy, clientId, clientData, orderResult, blockSubmit, comment, currentPage}) => {
    return {periodData, brand, sortBy, clientId, orderResult, blockSubmit, comment, currentPage, cartItems, clientData};
};

const mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        pageChange: (page) => dispatch(pageChange(page)),
        commentChanged: (text) => dispatch(commentChange(text)),
        selectedItem: (id) => {
            dispatch(selectedItem(id));
            history.push("/card");
        },
        clearResult: () => {
            history.push("/");
            dispatch(clearResult())
        },
        selectBrand: (brand) => dispatch(brandSelected(brand)),
        selectSorting: (order) => dispatch(sortingSelected(order)),
        cartChanged: (itemId, quant) => {
            dispatch(cartDataChange(itemId, quant));
        },
        pushCache: (data, clientGuid) => {
            const items = data.filter((i, k) => {
                return i.amount > 0
            });
            if (items.length) {
                const requestData = {
                    clientGuid: clientGuid,
                    data: {data: items}
                };
                dispatch(cacheRequested());
                hammerService.pushCache(requestData)
                    .then((data) => {
                        dispatch(cacheLoaded(data))
                    })
                    .catch((err) => {
                        dispatch(cacheFail(err))
                    });
            }
        },
        pushOrder: (data, comment, clientId) => {
            const items = data.filter((i, k) => {
                return i.to_order > 0
            });
            if (items.length) {
                const req = items.map((i, k) => {
                    return {id: i.guid, amount: i.to_order}
                });

                const requestData = {
                    id: clientId,
                    data: req,
                    comment: comment
                };

                dispatch(orderRequested())
                hammerService.pushOrder(requestData)
                    .then((data) => {
                        dispatch(orderLoaded(data))
                    })
                    .catch((err) => {
                        dispatch(orderFail(err))
                    });
            } else {
                openNotification();
            }
        }
    };
}

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(OrdersTable);
