import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { LoginPage, SearchPage, ClientSimpleData, Card, ProductsPage, ProductCard, CartPage } from '../pages';

import './app.css';

const App = () => {
    return (
        <main role='main' className='container'>
            <Switch>
                {/*<Route path={"/"} component={TestPage} exact />}*/}
                <Route path="/products" component={ProductsPage} exact/>
                <Route path="/productCard" component={ProductCard} exact/>
                <Route path="/cart" component={CartPage} exact/>

                <Route path={"/card"} component={Card} exact />}
                <Route path="/" component={SearchPage} exact/>
                <Route path="/login" component={LoginPage} exact/>
                <Route path="/client" component={ClientSimpleData} exact/>
            </Switch>
        </main>
    );
}

export default App;