import React from 'react';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom";
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { changedLoginValues, loginRequested, loginSuccess, loginError} from '../../actions';


import 'antd/dist/antd.css';
import './login-page.css';
import { compose } from "../../utils";
import withHammerService from "../hoc/with-hammer-service";

// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 8,
//     span: 16,
//   },
// };

const LoginPage = ({logined, onLoginChanged, loginFormData, sendLoginData}) => {
    if (logined){
        return <Redirect to='/' />;
    }

    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
            <Col span={16} >
                <Form
                    onValuesChange={(data) => onLoginChanged(data)}
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}>

                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Ведите логин!' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Логин" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Ведите пароль!'}]}>
                        <Input prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Пароль" />
                    </Form.Item>

                    <Form.Item>
                        <Button block
                                type="primary"
                                htmlType="submit"
                                onClick={ () => sendLoginData(loginFormData)}
                                className="login-form-button">Войти</Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({logined, loading, error, loginFormData}) => {
    return { logined, loading, error, loginFormData};
};

const  mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        onLoginChanged: (data) => dispatch(changedLoginValues(data)),
        sendLoginData: (loginFormData) => {
            hammerService.login(loginFormData)
                .then((data) => dispatch(loginSuccess(data)))
                .catch((err) => dispatch(loginError(err)))
            dispatch(loginRequested());
        }
    };
}

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(LoginPage);
