import React from 'react';
import ProductItemCard from '../product-item-card';

const ProductCard = () => {
    return(
        <ProductItemCard />
    );
}

export default ProductCard;
