import React, {Component} from 'react';
import {
    productItemRequested,
    productItemLoaded,
    productItemFail,
    addProductToCart,
    removeProductFromCart,
    changeProductCount
} from '../../actions';

import Spinner from '../spinner';
import {connect} from "react-redux";
import {compose} from "../../utils";
import {Link, Redirect} from 'react-router-dom';
import withHammerService from "../hoc/with-hammer-service";

import './product-item-card.css';
import {Button, Row, Col, Card, InputNumber, Form} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

class ProductItemCard extends Component {

    componentDidMount() {
        const {itemGuid, getItem} = this.props;
        if (itemGuid) {
            getItem(itemGuid);
        }
    }

    render(){
        const {loading, itemData, itemGuid, cartItems, addToCart, removeFromCart, changeProductCount} = this.props;

        const items = cartItems.filter((e, key) => {
            return e.guid === itemGuid;
        });

        let inCart = 0;
        if (items.length) {
            inCart = items[0].to_order;
        }

        if (loading) {
            return <Spinner/>;
        }

        if (!itemGuid) {
            return <Redirect to='/' />;
        }

        if (!itemData) {
            return '';
        }

        const itemInfo = {
            "brand": itemData.brand,
            "guid": itemData.guid,
            "name": itemData.name,
            "sku": itemData.sku
        };

        return (
            <React.Fragment>
                <Row type="flex" justify="center" align="middle">
                    <Col span={22} style={{paddingTop: '10px'}}>
                        <Link to="/products">
                            <Button type="primary" shape="round" icon={<ArrowLeftOutlined/>} size={'small'}>Назад</Button>
                        </Link>
                        <br/>
                        <br/>
                        <Card
                            hoverable
                            style={{width: '90%', margin: 'auto'}}
                            cover={<img alt="" src={itemData.photo}/>}
                        >
                            {itemData.name}
                            <br/>
                            {itemData.sku}
                            <br/>
                            {itemData.brand}
                            <br/>
                            {itemData.description}
                            <br/>
                            <b>Остаток:</b> {itemData.remain}
                            <br/>
                            <b>Цена:</b> {itemData.price}
                        </Card>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Col span={22} style={{paddingTop: '10px'}}>
                        <Form
                            style={{width: '90%', margin: 'auto'}}
                            layout="vertical">
                            <Form.Item label="В корзине">
                                <InputNumber
                                    size={'large'}
                                    min={0} max={1000}
                                    defaultValue={inCart}
                                    style={{width: '50%'}}
                                    // onStep={(value, info) => {
                                    //         if (info.type === 'up'){
                                    //             addToCart(itemInfo);
                                    //         } else {
                                    //             removeFromCart(itemInfo);
                                    //         }
                                    //     }
                                    // }
                                    onChange={(count) => changeProductCount(itemData, count)}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({products: {itemData, itemGuid, loading, cartItems}}) => {
    return {itemData, itemGuid, loading, cartItems};
};

const mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        getItem: (itemGuid) => {
            dispatch(productItemRequested())
            hammerService.getItem(itemGuid)
                .then((data) => dispatch(productItemLoaded(data)))
                .catch((err) => dispatch(productItemFail(err)))
        },
        addToCart: (data) => dispatch(addProductToCart(data)),
        removeFromCart: (data) => dispatch(removeProductFromCart(data)),
        changeProductCount: (data, count) => dispatch(changeProductCount({data, count}))
    }
};

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ProductItemCard);
