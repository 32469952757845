import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';
//import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from 'react-router-dom';

import App from './components/app';
import ErrorBoundry from './components/error-boundry';
import HammerService from './services/hammer-service';
import { HammerServiceProvider } from './components/hammer-service-context';
import { history } from './utils';

import store from './store';

const hammerService = new HammerService();

ReactDom.render(
    <Provider store={store}>
        <ErrorBoundry>
            <HammerServiceProvider value={hammerService}>
                <Router history={history}>
                    <App />
                </Router>
            </HammerServiceProvider>
        </ErrorBoundry>
    </Provider>,
    document.getElementById('root')
);
