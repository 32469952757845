import React, {Component} from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {Input, Col, Row, Select, InputNumber, Table, Typography, Button} from "antd";
import {sortBy as sorter} from "lodash";
import {
    productsSortingSelected,
    productsBrandSelected,
    searchProductRequested,
    searchProductSuccess,
    searchProductFail,
    productPageChange,
    selectedProductItem,
    addProductToCart,
    removeProductFromCart,
    changeProductCount,
    cacheRequested,
    cacheLoaded,
    cacheFail
} from '../../actions';
import EmptyLocale from "../empty-locale";

import {compose, history} from "../../utils";
import withHammerService from "../hoc/with-hammer-service";
import {connect} from "react-redux";
import {Link, Redirect} from 'react-router-dom';
import {ArrowLeftOutlined, ShoppingCartOutlined} from "@ant-design/icons";

import 'antd/dist/antd.css';
import './products.css';

const { Title, Text } = Typography;
const {Option} = Select;


class Products extends Component{
    constructor() {
        super();
        this.onCartChanged = this.onCartChanged.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    onCartChanged(item, count) {
        this.props.changeProductCount(item, count);
        setTimeout(() => {
            const ordersData = (this.props.periodData.data) ? this.props.periodData.data : [];
            const full = this.props.cartItems.concat(ordersData.filter((item) => {return item.to_order > 0}));
            const res = full.map((item, key) => {
                return {
                    id: item.guid,
                    amount: item.to_order
                };
            });
            //console.log("res", res)
            this.props.pushCache(res, this.props.clientData.guid);
        }, 500);
    }

    render() {

        const {searchQuery, selectSorting, selectBrand, addToCart, removeFromCart,
            selectedItem, productsCartChanged, pageChange, changeProductCount} = this.props;
        const {productsSearchValue, foundBrands, foundProducts, sortBy, brand, currentPage, cartItems} = this.props;

        const brandsOptions = foundBrands.map((brand, index) => {
            return <Option key={index} value={brand}>{brand}</Option>
        });

        const columns = [
            {
                title: 'Наименование',
                dataIndex: 'name',
                width: '75%',
                className: 'orders-table-column'
            },
            {
                title: 'Заказ',
                dataIndex: 'to_order',
                width: '25%',
                align: 'center',
                className: 'orders-table-column'
            }
        ];

        const newDs = sorter(foundProducts, (o) => {
            return o[sortBy]
        });

        const dataSource = newDs.map((item, index) => {
            const cItems = cartItems.filter((e, key) => {
                return e.guid === item.guid;
            });

            let inCart = 0;
            if (cItems.length) {
                inCart = cItems[0].to_order;
            }

            return {
                key: index,
                sortBrand: item.brand,
                brand: <Text>{item.brand}</Text>,
                name: (
                    <>
                        <Text type="secondary">Бренд: {item.brand}</Text>
                        <br/>
                        <Text
                            onClick={() => selectedItem(item.guid)}
                            strong>{item.name}</Text>
                        <br/>
                        <Text type="secondary">Артикул: {item.sku}</Text>
                        <br/>
                        <Text>Остаток: </Text><Text type="secondary">{item.remain}</Text>
                        <br/>
                        <Text>Цена: </Text><Text type="secondary">{item.price}</Text>
                    </>
                ),
                to_order: (<InputNumber
                        size={'large'}
                        min={0} max={1000}
                        defaultValue={inCart}
                        //value={inCart}
                        style={{width: '90%'}}
                        // onStep={(value, info) => {
                        //         if (info.type === 'up'){
                        //             addToCart(item);
                        //         } else {
                        //             removeFromCart(item);
                        //         }
                        //     }
                        // }
                        onChange={(count) => this.onCartChanged(item, count)}
                    />)
            }
        });

        let ds;
        if (brand) {
            ds = dataSource.filter((item) => item.sortBrand === brand);
        } else {
            ds = dataSource;
        }


        return(
            <div style={{paddingTop: '10px'}}>
                <Row style={{marginBottom: '10px'}}>
                    <Col span={12} style={{textAlign: 'left', paddingLeft: '15px'}}>
                        <Link to="/client">
                            <Button type="primary" shape="round" icon={<ArrowLeftOutlined/>} size={'small'}>Назад</Button>
                        </Link>
                    </Col>
                    <Col span={12} style={{textAlign: 'right', paddingRight: '15px'}}>
                        <Link to="/cart">
                            <Button type="primary" shape="round" icon={<ShoppingCartOutlined/>} size={'small'}>Корзина</Button>
                        </Link>
                    </Col>
                </Row>
                <Row type="flex" justify="center" align="top" style={{marginBottom: '20px'}}>
                    <Col span={22} >
                        <Title level={3}>Поиск товаров</Title>
                        <Input
                            onChange={(e) => {searchQuery(e.target.value)}}
                            placeholder="Введите название"
                            value={productsSearchValue}
                            style={{ width: '100%' }} />
                    </Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto'}}>
                    <Col span={12}><Text type="secondary">Сортировка: </Text></Col>
                    <Col span={12}><Text type="secondary">Отбор: </Text></Col>
                </Row>
                <Row style={{width: "90%", margin: 'auto', marginTop: '0px', marginBottom: '10px'}}>
                    <Col span={12}>
                        <Select
                            ref={(select) => this.brandSelect = select}
                            size={'default'}
                            defaultValue={null}
                            value={sortBy}
                            onChange={(value) => selectSorting(value)}
                            style={{width: '100%'}}
                        >
                            {[
                                {value: 'brand', alias: 'по бренду'},
                                {value: 'sku', alias: 'по артикулу'},
                                {value: 'name', alias: 'по наименованию'}
                            ].map((item, index) => {
                                return <Option key={index} value={item.value}>{item.alias}</Option>
                            })}
                         </Select>
                    </Col>

                    <Col span={12}>
                        <Text type="secondary"></Text>
                        <Select
                            ref={(select) => this.brandSelect = select}
                            allowClear={true}
                            //mode="multiple"
                            placeholder="Бренд фильтр"
                            size={'default'}
                            defaultValue={null}
                            value={brand}
                            onChange={(value) => {
                                this.brandSelect.blur();
                                selectBrand(value);
                            }}
                            //locale={{emptyText: <EmptyLocale />}}
                            style={{width: '100%'}}>
                            {brandsOptions}
                        </Select>
                    </Col>

                </Row>

                <Row style={{width: "90%", margin: 'auto'}}>
                    <Table
                        className={'orders-table'}
                        size={'small'}
                        bordered
                        columns={columns}
                        dataSource={ds}
                        locale={'zh-cn'}
                        pagination={{pageSize: 30, current: currentPage, onChange: (page) => {
                            pageChange(page);
                            //console.log(newDs)
                        } }}
                        scroll={{y: 490}}/>
                </Row>


            </div>
        );
    }
}

const mapStateToProps = ({periodData, clientData, products: {productsSearchValue, cartItems, foundBrands, foundProducts, sortBy, brand, currentPage}}) => {
    return {productsSearchValue, foundBrands, foundProducts, sortBy, cartItems, brand, clientData, currentPage, periodData};
};

const mapDispatchToProps = (dispatch, {hammerService}) => {
    return {
        searchQuery: (query) => {
            const queryString = query.trim()
            dispatch(searchProductRequested(query))
            if (queryString.length >= 3) {
                hammerService.productSearch(queryString)
                    .then((data) => dispatch(searchProductSuccess(data)))
                    .catch((err) => dispatch(searchProductFail(err)))
            }
        },
        selectSorting: (order) => dispatch(productsSortingSelected(order)),
        selectBrand: (brand) => dispatch(productsBrandSelected(brand)),
        selectedItem: (id) => {
            dispatch(selectedProductItem(id));
            history.push("/productCard");
        },
        //productsCartChanged: (data) => console.log(data),
        pushCache: (data, clientGuid) => {
            const items = data.filter((i, k) => {
                return i.amount > 0
            });
            if (items.length) {
                const requestData = {
                    clientGuid: clientGuid,
                    data: {data: items}
                };
                dispatch(cacheRequested());
                hammerService.pushCache(requestData)
                    .then((data) => {
                        dispatch(cacheLoaded(data))
                    })
                    .catch((err) => {
                        dispatch(cacheFail(err))
                    });
            }
        },
        addToCart: (data) => dispatch(addProductToCart(data)),
        removeFromCart: (data) => dispatch(removeProductFromCart(data)),
        pageChange: (page) => dispatch(productPageChange(page)),
        changeProductCount: (data, count) => dispatch(changeProductCount({data, count}))
    };
};

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Products);
