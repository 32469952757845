import React, {Component} from 'react';
import Spinner from '../spinner';
import { Row, Col, Dropdown, Button, Menu, Card, Typography} from 'antd';
import { compose} from '../../utils';
import OrdersTable from '../orders-table';
import { connect } from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import withHammerService from "../hoc/with-hammer-service";
import {
    clearToken,
    periodRequested,
    periodSuccess,
    periodFail,
    periodAliasChanged,
    clearClient
} from '../../actions';
import {SyncOutlined, CloseOutlined, CalendarOutlined, CheckOutlined, ShoppingCartOutlined} from '@ant-design/icons';

import 'antd/dist/antd.css';

//import moment from 'moment';

import 'moment/locale/ru';
//import locale from 'antd/es/locale/ru_RU';

//const { RangePicker } = DatePicker;
//const dateFormat = 'YYYY-MM-DD';
const { Text, Title } = Typography;


class ClientSimpleData extends Component{

    componentDidMount() {
        const {clientId, clientPeriod, periodData} = this.props;
        if (clientId && !periodData) {
            this.props.getPeriod({
                "from": clientPeriod[0],
                "to": clientPeriod[1]
            }, clientId)
        }
    }

    render() {
        const {
            loading,
            clientId,
            userData,
            clientData,
            clientPeriod,
            clientPeriodAlias,
            changePeriod,
            shouldUpdate,
            getPeriod,
            clearToken,
            logined,
            periodData,
            clearClient
        } = this.props;

        if (!logined){
            return <Redirect to='/login' />;
        }

        if (!clientId) {
            return <Redirect to='/' />;
        }

        if (loading) {
            return <Spinner/>
        }

        const menu = (
            <Menu
                style={{width: 230}}
                onClick={({key})=> changePeriod(key)}
            >
                {[
                    {alias: 'year', name: 'Год'},
                    {alias: 'month', name: 'Месяц'},
                    {alias: 'week', name: 'Неделя'},
                ].map((e, k) => {
                    if (e.alias === clientPeriodAlias){
                        return <Menu.Item key={e.alias} value={e.alias}>{e.name} <CheckOutlined /></Menu.Item>
                    } else {
                        return <Menu.Item key={e.alias} value={e.alias}>{e.name}</Menu.Item>
                    }
                })}
            </Menu>
        );

        const contraData = () => {
            if (clientData) {
                return (
                    <div style={{paddingTop: '10px'}}>
                        <Row style={{marginBottom: '10px'}}>
                            <Col span={12} style={{textAlign: 'left', paddingLeft: '15px'}}>
                                <Link to="/products">
                                    <Button type="primary" shape="round" size={'small'}>Поиск товаров</Button>
                                </Link>
                            </Col>
                            <Col span={12} style={{textAlign: 'right', paddingRight: '15px'}}>
                                <Link to="/cart">
                                    <Button type="primary" shape="round" icon={<ShoppingCartOutlined/>} size={'small'}>Корзина</Button>
                                </Link>
                            </Col>
                        </Row>
                        <Card
                            size="small"
                            bordered={true} style={{width: "90%", margin: 'auto', marginTop: '20px', marginBottom: '20px'}}>
                            {/*<Row type="flex" justify="space-between">*/}
                            <Row>
                                <Col span={22}>
                                    <Title style={{marginTop: '0.1em'}} level={5}>{clientData.name}</Title>
                                    <Text>Дебиторка:</Text> <Text type="danger" strong>{periodData.balance} у.е.</Text>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        onClick={clearClient}
                                        //shape="round"
                                        shape="circle"
                                        icon={<CloseOutlined />}
                                        size={'small'}
                                    ></Button>
                                </Col>
                            </Row>

                            {/*<Divider style={{marginTop: '10px'}}/>*/}



                            <Dropdown.Button
                                trigger={'click'}
                                style={{marginTop: '10px'}}
                                placement="bottomLeft"
                                icon={<CalendarOutlined />}
                                overlay={menu}>
                                {clientPeriod[0]} -> {clientPeriod[1]}
                            </Dropdown.Button>

                            {shouldUpdate &&
                            <Button
                                onClick={() => getPeriod(
                                    {
                                        "from": clientPeriod[0],
                                        "to": clientPeriod[1]
                                    },
                                        clientId
                                    )}
                                    type="primary"
                                    shape="circle"
                                    style={{marginLeft: '20px'}}
                                    icon={<SyncOutlined/>}/>
                            }
                        </Card>
                        </div>
                );
            } else {
                return '';
            }
        }

        const ordersTable = () => {
            if (clientData) {
                return(
                    <OrdersTable />
                );
            } else {
                return '';
            }
        }

        return (
            <div>
                {contraData()}
                {ordersTable()}
            </div>
        );
    }
}

const mapStateToProps = ({logined, loading, error, clientId, periodData,
                             userData, clientData, clientPeriod, clientPeriodAlias, shouldUpdate }) => {
    return { logined, loading, error, clientId, userData, periodData,
        clientData, clientPeriod, clientPeriodAlias, shouldUpdate };
};

const  mapDispatchToProps = (dispatch, { hammerService }) => {
    return {
        clearToken: () => dispatch(clearToken()),
        getPeriod: (period, clientId) => {
            dispatch(periodRequested())
            hammerService.getPeriod(period, clientId)
                .then((data) => dispatch(periodSuccess(data)))
                .catch((err) => dispatch(periodFail(err)))
        },
        changePeriod: (periodAlias) => {
            dispatch(periodAliasChanged(periodAlias));
        },
        clearClient: () => dispatch(clearClient())
    }
};

export default compose(
    withHammerService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ClientSimpleData);

